<template>
  <section class="develop">
    <imageBanner :imgBannerObj="imgBannerObj" />
    <section class="container">
      <div class="title">
        <h2>{{ options.title }}</h2>
        <div class="line"></div>
        <p>{{ options.en }}</p>
        <p class="tit">{{ options.title1 ? options.title1 : options.title }}</p>
      </div>
      <el-row :gutter="50">
        <el-col :span="12"><ArticleContent :data="options.content" class="information-content" /></el-col>
        <el-col :span="12"><el-image :src="options.img"></el-image></el-col>
      </el-row>
    </section>
  </section>
</template>

<script>
import imageBanner from '../../components/ImageBanner.vue'
import ArticleContent from '../../components/ArticleContent.vue'
export default {
  components: {imageBanner, ArticleContent},
  data () {
    return {
      imgBannerObj: {
        url: require('../../assets/images/aboutus/可持续发展.png'),
        slogan: require('../../assets/images/2023/develop/slogan.png'),
        class: 'developSlogan'

      },
      options: {title: '', content: []}
    }
  },
  watch: {
    '$route.query' () {
      this.getOptions()
    }
  },
  created () {
    this.getOptions()
  },
  methods: {
    getOptions () {
      let id = (this.$route.query.id).toString()
      switch (id) {
        case '1':
          this.options = this.$store.getters.getDevelop1 && JSON.parse(JSON.stringify(this.$store.getters.getDevelop1))
          break
        case '2':
          this.options = this.$store.getters.getDevelop2 && JSON.parse(JSON.stringify(this.$store.getters.getDevelop2))
          break
        case '3':
          this.options = this.$store.getters.getDevelop3 && JSON.parse(JSON.stringify(this.$store.getters.getDevelop3))
          break
        case '4':
          this.options = this.$store.getters.getDevelop4 && JSON.parse(JSON.stringify(this.$store.getters.getDevelop4))
          break
        default:
          this.options = this.$store.getters.getDevelop1 && JSON.parse(JSON.stringify(this.$store.getters.getDevelop1))
          break
      }
    }
  }
}
</script>

<style scoped>
.develop {background-color: #ecf0f5; padding-bottom: 1rem;}
.develop .title {text-align: center; padding: 1rem 0 5rem; line-height: 2; font-size: 2rem; color: #666;}
.develop .title .line {width: 2em; height: 2px; background-color: #e67700; margin: 0 auto;}
.develop .title  .tit {font-size: 1.2rem;}
.information-content {padding-top: 2rem; border-top: 1px solid #999;}
</style>